form {
	> p:first-child {
		color: #5c53a7;
		font-size: 18px;
		font-weight: 500;
		line-height: 20px; /* 111.111% */
		margin-bottom: 24px;
	}
}

.text_photo_container {
	display: flex;
	flex-direction: column;
	gap: 16px;
	> div:first-child {
		> div:last-child {
			height: 68px;
		}
	}
}

.damagePage__content {
	padding-top: 8px;
}

.damagePagePhoto {
	padding-bottom: 54px;
}

.damagePagePhoto__add {
	position: absolute;
	bottom: 0;
	padding: 8px 12px;
	border-radius: 12px;
	background-color: #ffffff;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	> p {
		color: #1c1c1e;
		font-size: 18px;
		font-weight: 600;
		line-height: 24px;
	}
}

// Вкладка информации о повреждении
.container {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.infoTitle {
	font-family: Montserrat;
	font-size: 18px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.currentDamageCheck {
	display: flex;
	align-items: center;
	gap: 12px;

	& > :first-child p {
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 400;
		line-height: 24px;
		text-align: left;
	}
}
.textInfo {
	display: flex;
	flex-direction: column;
	gap: 12px;
	& > :first-child {
		font-family: Montserrat;
		font-size: 16px;
		font-weight: 500;
		line-height: 20px;
		letter-spacing: 0.03500000014901161px;
		text-align: left;
	}
}
