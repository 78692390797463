.cardContainer {
  border-radius: 16px;
  border: 2px solid #c7bfff;
  background: #fffbff;
  // box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
  //   0px 8px 18px -6px rgba(24, 39, 75, 0.12);
  backdrop-filter: blur(6px);
  padding: 8px 16px;
  display: flex;
  gap: 12px;
  justify-content: center;
  flex-direction: column;
  // min-height: 88px;
  width: 100%;
  &::before {
    content: "";
    position: absolute;
    height: 34px;
    left: -1px;
    border: 2px solid;
    border-color: #c7bfff;
    border-radius: 0px 8px 8px 0px;
  }
}

.activeCardContainer {
  border: 2px solid #ff8e16 !important;
  &::before {
    border-color: #ff8e16 !important;
  }
}

.cardContainer__row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.addIcont {
  position: absolute;
  right: -16px;
  button div {
    transform: rotate(45deg);
  }
}

.delIcon {
  left: -16px !important;
  button div {
    transform: rotate(0deg);
  }
}

.cardContainer__name {
  color: #1C1C1E;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.08px;
}

.cardContainer__info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;

  p {
    color: #1C1C1E;
    text-align: right;
    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0.08px;
  }
}

.cardContainer__status {
  color: #5C53A7;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px; /* 166.667% */
  letter-spacing: 0.08px;
}

.cardContainer__details {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4px;
}

.details__danger,
.details__damage {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  background: #de3730;
}
.details__damage {
  background: #ff8e16;
}
