.containers {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 20px;
	// height: 70vh;
}

.topBlock {
	display: flex;
	justify-content: space-between;
	div:first-child p {
		color: #fffbff;
		font-size: 14px;
		font-weight: 400;
		line-height: 18px;
		margin-top: 2px;
	}
	button div {
		transform: rotate(45deg);
	}
}

.dataClient__footer {
	display: flex;
	justify-content: flex-end;
	gap: 8px;
}

.containers__list {
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	gap: 12px;
	align-items: flex-end;
	overflow-y: auto;
	> div {
		display: flex;
		// overflow: auto;
		flex-direction: column;
		gap: 8px;
		height: 100%;
		width: 100%;
		// overflow-y: scroll;
	}

	> button {
		max-width: fit-content;
	}
	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}
}

.activeBtns {
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
}

.containersItem {
	display: flex;
	flex-direction: row;
	gap: 4px;
	max-height: 62px;
}

.activeContainersItem {
	padding-right: 18px;
}

.containersItem__actions {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.delActiveContainerItem {
	padding-right: 0px !important;
	padding-left: 18px !important;
}

.mobTopLine {
	display: flex;
	padding: 0px 16px 16px 16px !important;
	justify-content: space-between;
	border-bottom: 2px solid #3c4858;
	p {
		color: #f3eeff;
		font-size: 16px;
		font-weight: 500;
		line-height: 24px; /* 150% */
		letter-spacing: 0.08px;
	}
}

@media (max-width: 639.9px) {
	.containers {
		justify-content: flex-end;
		height: calc(100svh - 162px);

		z-index: 0;
		position: absolute;
		width: 100% !important;
		left: 0;
		bottom: 32px;
	}
	.dataClient__footer {
		display: none;
	}
	.containers__container {
		height: 100%;
		max-height: 100%;
	}
}

.emptyList {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100%;
	gap: 12px;
	> p:first-child {
		color: #1c1c1e;
		font-size: 16px;
		font-weight: 500;
		line-height: 16px; /* 100% */
		letter-spacing: 0.08px;
	}
	> p:last-child {
		color: #3a3a3c;
		font-size: 12px;
		font-weight: 400;
		line-height: 16px;
		letter-spacing: 0.08px;
	}
}
