.cardOrder {
  border-radius: 16px;
  border: 2px solid #c7bfff;
  background: #fffbff;
  backdrop-filter: blur(6px);
  padding: 12px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 144px;
  cursor: pointer;

  &::before {
    content: "";
    position: absolute;
    height: 80px;
    left: -1px;
    top: 29px;
    border: 2px solid;
    border-color: #c7bfff;
    border-radius: 0px 8px 8px 0px;
  }
}

.cardOrder__row {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.cardOrder__name,
.cardOrder__number {
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.08px;
}

.cardOrder__info {
  display: flex;
  flex-direction: column;
  gap: 4px;

  p {
    color: #000;
    font-size: 12px;
    font-weight: 500;
    line-height: 20px;
    letter-spacing: 0.08px;
  }
}

.cardOrder__info_primary {
  margin-left: 2px;
  color: #443a8e !important;
}

.cardOrder__info_success {
  margin-left: 2px;
  color: #149527 !important;
}

.cardOrder__info_error {
  margin-left: 2px;
  color: #ba1a1a !important;
}

.activeCardOrder {
  border: 2px solid #FF8E16 !important;
  &::before {
    border-color: #FF8E16 !important;
  }
}