.act_main_block {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding: 12px;
	border-radius: 16px;
	background: #fffbff;
	border: 2px solid #c7bfff;
	position: relative;
}

.act_left_right {
	border-radius: 16px;
	background: #c7bfff;
	position: absolute;
	width: 4px;
	height: 76px;
	left: -2px;
}
.topBlock {
	display: flex;
	justify-content: space-between;
	> p:last-child {
		color: #2d2276;
		font-family: Montserrat;
		font-size: 12px;
		font-weight: 600;
		line-height: 16px; /* 133.333% */
		letter-spacing: 0.08px;
	}
}

.act_title_num {
	color: #5c53a7;
	font-size: 16px;
	font-weight: 600;
	line-height: 24px;
	letter-spacing: 0.08px;
}

.act_num_name {
	margin-top: 8px;
	margin-bottom: 20px;
	> p {
		color: #000;
		text-align: right;
		font-size: 14px;
		font-weight: 500;
		line-height: 22px;
		letter-spacing: 0.08px;
	}
}

.act_inspection_damage,
.act_import,
.act_inspection_damage {
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.act_num_name {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 4px;
}

.act_import,
.act_inspection_damage,
.act_import {
	color: #000;
	font-size: 12px;
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0.08px;
}
