.container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 10px;
}

.sideBlock {
    box-shadow: 0px 0px 10px -5px #000;
    border-radius: 8px;
    padding: 16px;
}

@media (max-width: 720px) {
    .container {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .sideBlock {
        box-shadow: 0px 0px 0 0 #000;
        border: 1px solid #eee;
        padding: 16px;
    }
}