// скролл внутри таблицы
.table__container {
	margin-top: 8px;
	max-width: calc(100vw - 16px);
	box-shadow: 0px 2px 8px 0px #0000001f;

	@media (min-width: 1279.9px) {
		display: inline-grid;
		max-width: 100%;
		width: 100%;
	}
}
.table__container > div {
	> div:nth-child(2)::-webkit-scrollbar {
		width: 8px;
		height: 8px;
	}

	> div:nth-child(2)::-webkit-scrollbar-track {
		margin-top: 51px;
		background-color: #8e96c4;
		border-radius: 0;
	}

	> div:nth-child(2)::-webkit-scrollbar-thumb {
		background-color: #343c6a;
		border-radius: 0;
	}
}

// шапка таблицы
thead tr {
	th {
		color: #343c6a !important;
		font-size: 14px !important;
		font-weight: 600 !important;
		line-height: 22px !important;
		border-bottom: 1px solid #e4dfff !important;
		padding: 9px 10px 9px 12px !important;

		div {
			color: #343c6a !important;

			div:nth-child(3) {
				margin-right: 0 !important;
				right: -5px !important;
				padding: 0 !important;

				hr {
					transform: scale(1.2) translateX(4px) !important;
					border-color: #343c6a !important;
					border-width: 1px !important;
					height: 18px !important;
				}
			}

			.MuiInputAdornment-root {
				width: 40px;
			}
		}

		button {
			color: #343c6a !important;
			opacity: 1 !important;
			padding: 0 !important;
		}
	}

	th:nth-child(1) {
		min-width: 48px !important;
		// width: 48px !important;
		max-width: 48px !important;
		padding: 0 !important;
		display: flex !important;
		align-items: center !important;
		justify-content: center !important;
		text-align: center !important;

		> div {
			justify-content: center !important;
		}

		span {
			color: #ffffff3d !important;
		}
		svg {
			fill: #343c6a !important;
		}
	}
}

// ячейки таблицы
td {
	border-bottom: 1px solid #e4dfff !important;
	padding: 8px 8px 8px 12px !important;
	font-family: 'SFProText' !important;
	font-weight: 400;
	height: 40px !important;
	font-size: 14px !important;
	line-height: 20px !important;
	color: #343c6a !important;

	input {
		font-family: 'SFProText' !important;
	}
	
	a {
		color: #5856D6;
		text-decoration: underline;
	}
}

td:first-child {
	text-align: center;
	padding: 4px 0 !important;
	min-width: 48px !important;
	max-width: 48px !important;

	span {
		margin: 0 auto !important;
	}

	svg {
		path {
			color: #5856D6 !important;
		}
	}

	.MuiTypography-root {
		color: #343c6a !important;
	}
}

tbody tr td:only-child {
	text-align: center;
	padding: 4px 0 !important;
	min-width: 100% !important;
	max-width: 100% !important;

	.MuiTypography-root {
		color: #F1F3F9 !important;
		font-family: 'AvenirNextCyr' !important;
		font-style: normal !important;
	}
}

// футер
.table__footer {
	width: 100%;
	justify-content: flex-end;
	color: #343c6a;
	padding: 8px 12px;
	display: flex;
	align-items: center;
	gap: 12px;
	min-height: 52px;

	@media (max-width: 719.9px) {
		justify-content: flex-start;
		align-items: flex-start;

		div {
			font-size: 12px;
		}
	}
}

.table__footer_btns {
	display: flex;
	align-items: center;
	gap: 12px;
}

.table__footer_preloader {
	display: flex;
	align-items: center;
	gap: 12px;
}

// модальное окно редактирования одного контейнера
@media (max-width: 639.9px) {
	.MuiDialog-container.MuiDialog-scrollPaper {
		align-items: flex-end !important;
	}
}

.MuiDialog-paper {
	// background-color: transparent !important;
	max-width: 500px !important;
	box-shadow: none !important;
	margin: 0 !important;

	@media (max-width: 639.9px) {
		max-width: 100% !important;
		width: 100% !important;
	}

	.MuiPickersLayout-root:first-child {
		background-color: #fffbff !important;
		border-radius: 18px;
	}
}

.footer__btnsChanges {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 12px;
}

.footer__btns {
	display: flex;
	gap: 12px;

	button:nth-child(2) {
		width: 100%;
	}
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper {
	border-radius: 8px !important;
	background: #ffffff !important;
	box-shadow: 0px 4px 4px 0px #00000040 !important;
	backdrop-filter: blur(6px) !important;
	padding: 0 !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar {
	width: 2px !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-track {
	background-color: #e4dfff !important;
	border-radius: 4px !important;
}

div.MuiPaper-elevation.MuiPaper-rounded.MuiPopover-paper.MuiMenu-paper.MuiMenu-paper::-webkit-scrollbar-thumb {
	background-color: #8f86de !important;
	border-radius: 4px !important;
}

ul.MuiMenu-list {
	background-color: #ffffff;

	li {
		padding: 0 12px !important;
		min-height: 32px !important;
	}

	li,
	li > div,
	li > div label span {
		font-family: 'SFProText' !important;
		color: #343c6a !important;
		font-size: 14px !important;
		font-weight: 400 !important;
		line-height: 20px !important;
		letter-spacing: 0.035px;
	}

	li > div div i {
		color: #343c6a !important;
	}

	> div {
		button {
			text-transform: initial !important;
			font-family: 'SFProText' !important;
			color: #000000 !important;
			border-radius: 8px !important;
			font-size: 14px;
			font-weight: 400;
			line-height: 24px;
		}

		button:nth-child(2) {
			display: none !important;
		}
	}
}

.table__preloader {
	position: absolute;
}

.tableContainers {
	thead tr {
		th:nth-child(2) {
			display: none !important;
		}
	}

	td:nth-child(2) {
		display: none !important;
	}
}

[class*='MuiListItemIcon'] {
	color: #343c6a !important;
} 