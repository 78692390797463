.choiceClient {
	border-radius: 24px;
	background: #fffbff;
	// padding: 16px;
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.choiceClient__title {
	color: #1c1c1e;
	font-size: 18px;
	font-weight: 600;
	line-height: 26px;
}

.choiceClient__body {
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.choiceClient__search {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 8px;
}

// лист клиентов
.choiceClient__list {
	display: flex;
	padding-right: 8px;
	gap: 8px;
	flex-wrap: wrap;
	// max-height: 56vh;
	max-height: calc(100vh - 290px);
	overflow-y: auto;
	width: 100%;

	// скролл
	&::-webkit-scrollbar {
		width: 4px;
	}

	&::-webkit-scrollbar-track {
		background-color: #ffdebc;
		border-radius: 4px;
	}

	&::-webkit-scrollbar-thumb {
		background-color: #ff8e16;
		border-radius: 4px;
	}

	// @media (max-width: 1279.9px) {
	// 	max-height: calc(100vh - 348px);
	// }
	// @media (max-height: 865px) {
	// 	flex-wrap: wrap;
	// 	max-height: calc(100vh - 380px);
	// }
}

.client_info {
	display: flex;
	justify-content: space-between;
	gap: 24px;
	align-items: center;
}

.topLine {
	display: flex;
	justify-content: space-between;
	align-items: center;

	button {
		div {
			transform: rotate(45deg) !important;
		}
	}
}

// карточка клиента
.choiceClient__item {
	border-radius: 16px;
	border: 2px solid #c7bfff;
	background: #fffbff;
	// box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12),
	//   0px 8px 18px -6px rgba(24, 39, 75, 0.12);
	backdrop-filter: blur(6px);
	width: min-content;
	min-width: calc(33% - 4px);
	padding: 3px 12px 3px 6px;
	display: flex;
	gap: 12px;
	transition: 0.2s ease-in-out;
	position: relative;
	cursor: pointer;

	&::before {
		content: '';
		position: absolute;
		transition: 0.2s ease-in-out;
		height: 34px;
		left: -1px;
		top: 12px;
		border: 2px solid;
		border-color: #c7bfff;
		border-radius: 0px 8px 8px 0px;
	}

	&.active,
	&:hover {
		border: 2px solid #ff8e16;

		&::before {
			border-color: #ff8e16;
		}
	}
	// @media (max-width: 1279.9px) {
	//   max-width: calc(52% - 4px);
	// }
}

.item__img {
	border-radius: 12px;
	min-width: 56px;
	height: 56px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 24px;
}

.item__info {
	color: #1c1c1e;
	font-weight: 500;
	display: flex;
	justify-content: space-between;
	flex-direction: column;
}

.item__info_name {
	font-size: 14px;
	font-weight: 500;
}

.item__info_contract {
	font-size: 12px;
	line-height: 20px;
	letter-spacing: 0.08px;
}

@media (max-width: 743.9px) {
	.topLine {
		padding: 0 12px;
	}
	.choiceClient__body {
		padding: 0px;
	}
	.choiceClient {
		gap: 16px;
	}
}

.choiceClient_withSideBar {
	width: 100%;
	// & div:first-child {
	// 	width: 100%;
	// }
	// display: flex;
	// flex-direction: row;
	// flex-wrap: wrap;
	// gap: 8px;
	// align-content: flex-start;
	// padding-right: 8px;
	// overflow-x: hidden !important;
	// width: calc(100% - 475px);
	// overflow-y: scroll;
	// width: 100%;

	// @media (min-width: 743.9px) {
	// 	overflow: scroll;
	// }

	// @media (min-width: 1023.9px) {
	// 	width: 100%;
	// 	min-width: 354px;
	// }
}
