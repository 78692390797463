.repairServiceContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
    margin: 10px;
}

.repairInfo {
    box-shadow: 0px 0px 10px -5px #000;
    border-radius: 8px;
}

.serviceItem {
    display: grid;
    grid-template-columns: 42px 2fr 1fr;
    gap: 12px;
    align-items: end;
}

.viewer {
	display: flex;
	flex-wrap: wrap;
	gap: 4px;
    margin-top: 20px;
}

.onePhotoCard {
	position: relative;
	max-height: 106px;
}

.onePhotoCard img {
    max-width: 106px;
    aspect-ratio: 1/1;
    border-radius: 5px;
} 

.onePhotoCard > :last-child {
    left: 3px;
    bottom: 3px;
} 

@media (max-width: 720px) {
    .repairServiceContainer {
        display: flex;
        flex-direction: column;
        margin: 0;
    }

    .repairInfo {
        box-shadow: 0px 0px 0 0 #000;
        border: 1px solid #eee;
        padding: 16px;
    }
}

.photoContainer {
	border-radius: 8px;
	border: 2px dashed #aeaeb2;
	display: flex;
	height: 100px;
	min-height: 64px;
	justify-content: center;
	align-items: center;
	width: 100%;
	gap: 8px;
	align-self: stretch;
}

.uploadButton {
	cursor: pointer;
	color: #1c1c1e;
	font-size: 14px;
	font-weight: 500;
	line-height: 22px;
	letter-spacing: 0.08px;
	display: flex;
	gap: 8px;
	align-items: center;
	padding: 40px 8px;
	width: 100%;
	height: 100%;
	justify-content: center;
}